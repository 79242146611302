<template>
  <v-container pt-0 fluid>
    <v-layout row wrap pb-3>
      <div id="orderApprovalSearchFixed">
        <div class="searchFixed">
          <v-card outlined elevation="2" class="ma-3">
            <v-card-text>
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('actions.search')"
                single-line
                :color="inputColor"
                hide-details
              />
            </v-card-text>
          </v-card>
        </div>
      </div>
      <v-flex>
        <v-card
          id="orderApprovalCard"
          elevation="12"
        >
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`pages.${$route.name}`) }}
            </v-toolbar-title>

            <v-spacer />
            <v-text-field
              id="orderApprovalSearch"
              v-model="search"
              append-icon="search"
              :label="$t('actions.search')"
              single-line
              :color="inputColor"
              hide-details
            />
          </v-toolbar>
          <v-card-text class="py-2">
            
            <v-data-table
              fixed-header
              height="800"
              :headers="headers"
              :items="parsedData"
              :search="search"
              :items-per-page="-1"
              :footer-props="{
                'next-icon': 'mdi-chevron-right',
                'prev-icon': 'mdi-chevron-left'
              }"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td>{{ item.id }}</td>
                    <td>{{ item.orderCategoryName }}</td>
                    <td>{{ item.companyDepositoryName }}</td>
                    <td>{{ item.bagsName }}</td>
                    <td>{{ item.executionDate }}</td>
                    <td style="text-align: right; padding: 0;">
                      <v-btn
                        text
                        icon
                        rounded
                        color="warning"
                        @click="editItem(item.id)"
                      >
                        <v-icon>mdi-file-document-edit-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
              <tr slot="no-results">
                <td :colspan="headers.length" v-html="$t('helpers.notFound', { search })" />
              </tr>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
      id="formModal"
      v-model="formModal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      :max-width="width.dialog"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card>
        <v-card-title class="headline primary elevation-3" style="text-align: center; max-height: 58px;"><h5 class="white--text ma-0">{{ title }}</h5></v-card-title>
        <v-card-text style="flex: 1;">
          <v-layout row wrap pa-1 align-center justify-center>
            <v-flex class="pl-1 pr-1" xs12 sm :class="object.categoryOrderId === '1' ? 'sm6' : ''">
              <v-select
                v-model="object.categoryOrderId"
                :items="categoryOrders"
                :label="$t(`${objectName.multiple}.categoryOrderId`)" 
                :disabled="true"
                :color="inputColor"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1'" class="pl-1 pr-1" xs12 sm6>
              <v-select
                v-model="object.cycleId"
                :items="cycles"
                :label="$t(`${objectName.multiple}.cycleId`)" 
                :disabled="true"
                :color="inputColor"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1'" class="pl-1 pr-1" xs12 sm6>
              <v-select
                v-model="object.productId"
                :items="products"
                :label="$t(`${objectName.multiple}.productId`)" 
                :disabled="true"
                :color="inputColor"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1'" class="pl-1 pr-1" xs12 sm6>
              <v-select
                v-model="object.fieldBatchId"
                :items="fieldBatches"
                :label="$t(`${objectName.multiple}.fieldBatchId`)" 
                :disabled="true"
                :color="inputColor"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex class="pl-1 pr-1" xs12 sm4>
              <v-select
                v-model="object.installerId"
                :items="installers"
                :label="$t(`${objectName.multiple}.installerId`)" 
                :disabled="true"
                :color="inputColor"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex class="pl-1 pr-1" xs12 sm4>
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="object.executionDate"
                persistent
                lazy
                full-width
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="object.executionDate"
                    :label="$t(`${objectName.multiple}.executionDate`)" 
                    :disabled="true"
                    :color="inputColor"
                    :error="objectErrors.executionDate !== undefined"
                    :error-messages="parseErrors(objectErrors.executionDate)"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="object.executionDate" scrollable>
                  <v-spacer />
                  <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                  <v-btn flat color="primary" @click="$refs.dialog.save(object.executionDate)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-flex>
            <v-flex class="pl-1 pr-1" xs12 sm4>
              <v-text-field
                v-model="object.bagsQuantity"
                type="number"
                :label="$t(`${objectName.multiple}.bagsQuantity`)" 
                :disabled="true"
                :color="inputColor"
                :error="objectErrors.bagsQuantity !== undefined"
                :error-messages="parseErrors(objectErrors.bagsQuantity)"
                @change="adjustBags"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId !== '2' && object.categoryOrderId !== '4' && object.categoryOrderId !== '5'" class="pl-1 pr-1" xs12>
              <v-select
                v-model="object.kitsIds"
                :items="selectableKits()"
                :multiple="true"
                :label="$t(`${objectName.multiple}.kitsIds`)" 
                :disabled="true"
                :color="inputColor"
                item-text="helper"
                item-value="id"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId !== '1'" class="pl-1 pr-1" xs12>
              <v-select
                v-model="object.bagsIds"
                :items="bags"
                :multiple="true"
                :label="$t(`${objectName.multiple}.bagsIds`)" 
                :disabled="true"
                :color="inputColor"
                item-text="reference"
                item-value="id"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId === '1'" xs12>
              <v-data-table
                :headers="bagHeaders"
                :items="object.resultOrderBags"
                :search="search"
                :items-per-page="-1"
                :footer-props="{
                  'next-icon': 'mdi-chevron-right',
                  'prev-icon': 'mdi-chevron-left'
                }"
              >
                <template v-slot:body="{ items }">
                  <tr v-for="item in items" :key="item.id">
                    <td>
                      <v-text-field
                        v-model="item.reference"
                        :label="$t(`bags.reference`)" 
                        :disabled="!isEditting"
                        :error="!item.presenceInFieldBatch"
                        :error-messages="!item.presenceInFieldBatch ? $t('bags.noInFieldBatch') : []"
                        :color="inputColor"
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="item.estimatedWeight"
                        :label="$t(`bags.estimatedWeight`)"
                        type="number"
                        min="0" 
                        :disabled="!isEditting"
                        :color="inputColor"
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="item.realWeight"
                        :label="$t(`bags.realWeight`)"
                        type="number"
                        min="0" 
                        :disabled="!isEditting"
                        :color="inputColor"
                      />
                    </td>
                    <td>
                      {{ getKitByBag(item.kitId) }}
                    </td>               
                  </tr>
                </template>
              </v-data-table>
            </v-flex>
            <v-flex v-if="object.categoryOrderId === '4'" class="pl-1 pr-1" xs12>
              <h3> {{ $t(`${objectName.multiple}.replaceSensors`) }} </h3>
            </v-flex> 
            <v-flex v-if="object.categoryOrderId === '4'" class="pl-1 pr-1" xs12>
              <!-- Show the list of sensors  -->
              <template v-for="(sensor, index) in object.changeSensors">
                <!-- List with Identifier and sensor name -->
                <v-layout row wrap>
                  <v-flex xs12 sm4 class="px-2">
                    <v-text-field
                      v-model="sensor.identifier"
                      :label="$t(`${objectName.multiple}.sensorsName`)" 
                      :disabled="true"
                      :color="inputColor"
                    />
                  </v-flex>
                  <v-flex xs12 sm4 class="px-2">
                    <v-text-field
                      v-model="sensor.name"
                      :label="$t(`${objectName.multiple}.sensorsIdentifier`)" 
                      :disabled="true"
                      :color="inputColor"
                    />
                  </v-flex>
                  <v-flex xs12 sm2 class="px-2">
                    <!-- Checkbox -->
                    <v-checkbox
                      v-model="sensor.active"
                      :label="$t(`${objectName.multiple}.sensorActive`)"
                      :disabled="true"
                    />
                  </v-flex>
                  <v-flex xs12 sm2 class="px-2">
                    <!-- New sensor Name -->
                    <v-text-field
                      v-model="sensor.newName"
                      :label="$t(`${objectName.multiple}.newsensorsIdentifier`)" 
                      :disabled="true"
                      :color="inputColor"
                    />
                  </v-flex>
                </v-layout>
              </template>
            </v-flex>
            <v-flex v-if="object.categoryOrderId === '5'" class="pl-1 pr-1" xs12>
              <h3> {{ $t(`${objectName.multiple}.bags`) }} </h3>
            </v-flex>
            <v-flex v-if="object.categoryOrderId === '5'" class="pl-1 pr-1" xs12>
              <!-- For on resultBags -->
              <template v-for="(bag, index) in object.resultBags">
                <!-- List with Identifier and sensor name -->
                <v-layout row wrap>
                  <v-flex xs12 sm3 class="px-2">
                    <v-text-field
                      v-model="bag.reference"
                      :label="$t(`${objectName.multiple}.bagsName`)" 
                      :disabled="true"
                      :color="inputColor"
                    />
                  </v-flex>
                  <!-- Put buttons to click and open the url image  -->
                  <v-flex xs12 sm3 class="px-2">
                    <v-btn @click="openImage(bag.imageOne)" color="primary" class="ma-1" style="text-transform: none;">
                      {{ $t('helpers.seeImageOne') }}
                    </v-btn>
                    <!-- Have a button to open the location for image One -->
                    <v-btn @click="openLocationOnGoogleMaps(bag.imageOneLatitude, bag.imageOneLongitude)" color="primary" class="ma-0" style="text-transform: none;">
                      {{ $t('helpers.seeLocationOne') }}
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 sm3 class="px-2">
                    <v-btn @click="openImage(bag.imageTwo)" color="primary" class="ma-1" style="text-transform: none;">
                      {{ $t('helpers.seeImageTwo') }}
                    </v-btn>
                    <!-- Have a button to open the location for image Two -->
                    <v-btn @click="openLocationOnGoogleMaps(bag.imageTwoLatitude, bag.imageTwoLongitude)" color="primary" class="ma-0" style="text-transform: none;">
                      {{ $t('helpers.seeLocationTwo') }}
                    </v-btn>
                  </v-flex>
                  <!-- Validate if the presenceInFieldBatch -->
                  <v-flex xs12 sm3 class="px-2">
                    <!-- Chip with the information -->
                     <v-chip
                      :color="bag.presenceInFieldBatch ? 'success' : 'error'"
                      class="ma-1"
                    >
                      {{ bag.presenceInFieldBatch ? $t('bags.inFieldBatch') : $t('bags.noInFieldBatch') }}
                    </v-chip>
                  </v-flex>
                </v-layout>
              </template>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions v-if="isEditting" style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.discard') }}</v-btn>

          <v-spacer />

          <a-button v-if="object.id !== undefined && isEditting" :state="buttonState" color="primary" @click="saveChanges">{{ $t('actions.approve') }}</a-button>
          
          <a-button v-else-if="object.id === undefined" :state="buttonState" color="primary" @click="createItem">{{ $t('actions.create', { name: $t(`${objectName.multiple}.objectName`) }) }}</a-button>
        </v-card-actions>

        <v-card-actions v-else style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.close') }}</v-btn>
          
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline" style="font-size: 1.2em !important; text-align: center;">{{ $t('helpers.confirmDelete', { name: $t(`${objectName.multiple}.objectName`) }) }}</v-card-title>

        <v-card-actions style="text-align: center;">
          <v-spacer />

          <v-btn
            color="error"
            flat
            icon
            @click="deleteConfirm = false; deleteDialog = false; deleteId = 0"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>

          <v-btn
            color="success"
            flat
            icon
            @click="deleteConfirmation"
          >
            <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AButton from '@/components/Button.vue'

import { mapState, mapGetters } from 'vuex'

import add from '@/graphql/mutations/orders/add.gql'
import approve from '@/graphql/mutations/orders/approve.gql'
import object from '@/graphql/queries/orders/approveDetail.gql'
import moment from 'moment'

const objectName = {
  single: 'order',
  multiple: 'orders',
  object: {
    single: 'Order',
    multiple: 'Orders'
  }
}

export default {
  components: {
    AButton
  },

  data () {
    return {
      objectName,
      modal: false,
      pagination: { rowsPerPage: 20 },
      formModal: false,
      isEditting: true,
      deleteDialog: false,
      showPassword: false,
      deleteConfirm: false,
      buttonState: false,
      usableKits: [],
      deleteId: 0,
      object: {},
      objectErrors: {},
      search: '',
      headers: [
        {
          text: this.$i18n.t(`${objectName.multiple}.id`),
          align: 'left',
          sortable: true,
          value: 'id'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.categoryOrderId`),
          align: 'left',
          sortable: true,
          value: 'categoryOrdername'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.companyDepositoryId`),
          align: 'left',
          sortable: true,
          value: 'companyDepositoryName'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.bagsIds`),
          align: 'left',
          sortable: true,
          value: 'bagsName'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.executionDate`),
          align: 'left',
          sortable: true,
          value: 'executionDate'
        },
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          sortable: false,
          value: 'actions'
        }
      ],
      bagHeaders: [
        {
          text: this.$i18n.t(`bags.reference`)
        },
        {
          text: this.$i18n.t(`bags.estimatedWeight`)
        },
        {
          text: this.$i18n.t(`bags.realWeight`)
        },
        {
          text: this.$i18n.t('bags.kitId')
        }
      ]
    }
  },

  computed: {
    ...mapState(['user', 'bags', 'width', 'dark', 'language', 'categoryOrders', 'cycles', 'products', 'fieldBatches', 'installers', 'loraSensors', 'btSensors']),
    ...mapState({ data: objectName.multiple }),
    ...mapGetters(['inputColor', 'loraSensorsBtSensors', 'freeKitsLabeled', 'ownedKitsLabeled', 'freeBtSensors', 'freeLoraSensors']),
    ...mapGetters({
      data: 'finishedOrders'
    }),
    isMobile () {
      return this.width.full < 600
    },
    parsedData () {
      return this.data.map((item) => {
        item.orderCategoryName = this.categoryOrders.find((categoryOrder) => categoryOrder.id === item.categoryOrderId).name
        if (item.bagsIds.length > 0) {
          item.bags = this.bags.filter((bag) => item.bagsIds.includes(bag.id))
        }
        item.bagsName = item.bags.map((bag) => bag.reference).join(', ')
        return item
      })
    },
    defaultObject () {
      return {
        id: undefined,
        categoryOrderId: 0,
        cycleId: 0,
        productId: 0,
        fieldBatchId: 0,
        installerId: 0,
        executionDate: moment().format('YYYY-MM-DD'),
        sensors: [],
        bluetoothSensors: [],
        usableKits: [],
        bagsQuantity: 0,
        kitsIds: [],
        bags: []
      }
    },

    isFullScreen () {
      return this.width.full < 990
    },

    title () {
      if (!this.isEditting) {
        return this.$i18n.t(`${objectName.multiple}.titleShow`, { name: this.object.name })
      } else {
        if (this.object.id === undefined) {
          return this.$i18n.t(`${objectName.multiple}.titleNew`)
        } else {
          return this.$i18n.t(`${objectName.multiple}.titleEdit`, { name: this.object.name })
        }
      }
    }
  },

  created () {
    this.object = Object.assign({}, this.defaultObject)
    window.addEventListener('scroll', this.handleScroll)
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  mounted () {
    const card = document.querySelector('#orderApprovalCard')
    const search = document.getElementById('orderApprovalSearchFixed')

    var observer = new ResizeObserver(function (entries) {
      entries.forEach(function (entry) {
        search.style.width = `${card.offsetWidth}px`
      })
    })

    observer.observe(card)
  },

  methods: {
    handleScroll (event) {
      const elem = document.getElementById('orderApprovalCard')
      const search = document.getElementById('orderApprovalSearchFixed').childNodes[0]
      const searchCard = document.getElementById('orderApprovalSearch')

      var posTopView = window.scrollY
      var elemTop = elem.offsetTop + 126

      if (elemTop > posTopView) {
        search.classList.remove('visible')
        if (this.keyFocus) {
          searchCard.focus()
          this.keyFocus = false
        }
      } else {
        search.classList.add('visible')
        this.keyFocus = true
      }
    },

    getKitByBag (kitId) {
      const kit = this.selectableKits().find((item) => item.id === kitId)
      return kit.reference
    },
    selectableKits () {
      const kits = this.ownedKitsLabeled.filter((item) => this.usableKits.includes(item.id))
      const finalKits = [
        ...this.freeKitsLabeled,
        ...kits
      ]
      return finalKits
    },
    adjustBags () {
      const bags = []
      for (var i = 0; i < this.object.bagsQuantity; i++) {
        if (this.object.bags[i] === undefined) {
          bags.push({
            reference: 'Nueva Bolsa',
            estimatedWeight: 0
          })
        } else {
          bags.push(this.object.bags[i])
        }
      }

      this.object.bags = bags
    },

    parseErrors (array) {
      if (array === undefined) {
        return []
      }
      return array.map((currentValue, index, array) => {
        return currentValue.charAt(0).toUpperCase() + currentValue.substr(1)
      }).join(', ')
    },

    saveChanges () {
      this.graphQLWrapper(`approved${objectName.object.single}`)
    },

    async editItem (id) {
      if (this.object.id === undefined) {
        await this.showItem(id)
      }
      
      this.isEditting = true
    },

    async showItem (id) {
      await this.$apollo.query({
        query: object,
        variables: {
          id,
          apiToken: this.user.apiToken,
          language: this.language.toUpperCase()
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const result = response.data[objectName.single]
        
        if (result.status) {
          this.object = {
            ...result[objectName.single]
          }
          this.formModal = true
          this.isEditting = false
          this.usableKits = this.object.kitsIds
        } else {
          if (errors.limit === undefined) {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: (errors.id === undefined ? errors.apiToken : errors.id)
            })
          } else {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: errors.limit
            })
          }
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      })
    },

    discardChanges () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = false
      this.isEditting = false
    },

    newItem () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = true
      this.isEditting = true
    },

    createItem () {
      this.graphQLWrapper(`add${objectName.object.single}`)
    },

    graphQLWrapper (mutationName) {
      this.buttonState = true
      this.objectErrors = {}
      const object = Object.assign({}, this.object)
      object.executionDate = moment(this.object.executionDate).unix()
      object.bagsQuantity = parseInt(object.bagsQuantity)
      
      for (var i = object.bags.length - 1; i >= 0; i--) {
        delete object.bags[i].__typename
        object.bags[i].estimatedWeight = parseFloat(object.bags[i].estimatedWeight)
        object.bags[i].realWeight = parseFloat(object.bags[i].realWeight)
      }

      for (var i = object.resultBags.length - 1; i >= 0; i--) {
        delete object.resultBags[i].__typename
      }

      for (var i = object.resultOrderBags.length - 1; i >= 0; i--) {
        delete object.resultOrderBags[i].__typename
        
        object.resultOrderBags[i].estimatedWeight = parseFloat(object.resultOrderBags[i].estimatedWeight)
        object.resultOrderBags[i].realWeight = parseFloat(object.resultOrderBags[i].realWeight)
      }
      const variables = {
        object: object,
        apiToken: this.user.apiToken,
        language: this.language.toUpperCase()
      }

      delete variables.object.__typename

      let mutation = add

      if (mutationName.includes('approve')) {
        mutation = approve
      }

      this.$apollo.mutate({
        mutation,
        variables
      }).then(response => {
        const { status, errors, result } = response.data.approveOrder

        if (status === 'OK') {
          this.objectErrors = {}
          this.isEditting = false
          this.$store.dispatch(`get${objectName.object.multiple}`)
          this.$store.dispatch('getBags')
          this.$store.dispatch('getKits')
          this.$store.commit('toggleAlert', {
            type: 'success',
            message: this.$i18n.t(`${objectName.multiple}.success.approved`)
          })
        } else {
          this.objectErrors = errors
          this.$store.commit('toggleAlert', {
            type: 'warning',
            message: this.$i18n.t('errors.invalidFields')
          })
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      }).finally(() => {
        this.buttonState = false
      })
    },

    async deleteItem (id) {
      this.deleteId = id
      this.deleteDialog = true
    },

    async deleteConfirmation () {
      await this.$store.dispatch('deleteItem', {
        mutation: {
          graphql: `delete${objectName.object.single}`,
          vuex: `get${objectName.object.multiple}`
        },
        object: objectName.multiple,
        variables: {
          id: this.deleteId,
          apiToken: this.user.apiToken,
          language: this.language
        }
      })

      this.deleteDialog = false
      this.deleteId = 0
      this.deleteConfirm = false
    },
    openImage (url) {
      window.open(url, '_blank')
    },
    openLocationOnGoogleMaps (latitude, longitude) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`, '_blank')
    }
  }
}
</script> 
